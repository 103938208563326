<template>
  <div class="guest-container">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="host" tab="应用授权主体"><Host></Host></a-tab-pane>
      <!-- <a-tab-pane key="custom" tab="按客体授权到主体">
        按客体授权到主体</a-tab-pane
     > -->
    </a-tabs>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import Host from "./host.vue";
import { useRouter } from "vue-router";
export default {
  name: "Application",
  components: {
    //   IdaasDescription,
    Host,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loading: false,
      merchantAppId: "",
      activeKey: "host",
    });
    onMounted(() => {});
    const backUp = () => {
      router.push({ name: "authSystem" });
    };
    return {
      ...toRefs(state),
      backUp,
    };
  },
};
</script>

<style lang="scss" scoped>
.guest-container {
  min-height: 100%;
  padding-bottom: 10px;
  background: #fff;
}
.a-card.is-always-shadow {
  min-height: 100% !important;
}
.marginInfoList {
  margin: 10px 0;
  color: #6f7071;
  font-size: 14px;
  .infoLabel {
    text-align: center;
  }
  .infoValue {
    text-align: left;
  }
}
.cardBox {
  display: flex;
  justify-content: space-around;
  .ant-card-head-title {
    font-size: 12px;
  }
}
:deep(.ant-card) {
  width: 100% !important;
  height: 100%;
  font-size: 12px;
  margin: 0 10px;
  .ant-card-head {
    font-size: 12px;
    padding: 0 16px;
  }
  .ant-card-body {
    padding-bottom: 0;
    padding: 16px;
    .ant-btn {
      font-size: 12px;
    }
    .cardP {
      margin-bottom: 0;
      a {
        margin-right: 18px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .cardContent {
      color: #909399;
      height: 40px;
    }
  }
}
.alertContent {
  font-size: 12px;
}
.pStyle {
  font-size: 12px;
  color: #657180;
}
.pStyleTitle {
  font-size: 18px;
  font-weight: 900;
}
.secretStyle {
  font-weight: 900;
  margin-right: 20px;
}
.copy:hover {
  cursor: pointer;
}
.bottomDiv {
  display: flex;
  width: 100%;
  height: calc(100vh - 350px);
  justify-content: space-between;
  .cardWidth {
    width: 100% !important;
    height: 100% !important;
    margin: 0 5px;
  }
}
:deep(.ant-tabs-tab-btn) {
  padding: 0 10px;
}
</style>
