<template>
  <a-card class="guest-container">
    <a-alert
      message="授权管理"
      type="info"
      show-icon
      closable
      v-show="hostAlert === 'open'"
      @close="closeAlert"
      style="margin-bottom: 10px"
    >
      <template #icon><bulb-outlined style="margin-top: 20px" /></template>
      <template #description>
        <div class="alertContent">
          基于 RBAC 和 ABAC 的权限模型， 对系统自身和业务应用皆适用，
          左边是主体树（Subject）通常是人， 右边是客体树（Permission）,
          交叉形成权限组合（Privilege）
        </div>
      </template>
    </a-alert>
    <a-row style="width: 100%" :gutter="[16, 16]">
      <a-col :span="6">
        <a-card class="cardWidth">
          <a-tabs size="small" v-model:activeKey="leftActiveKey">
            <a-tab-pane
              key="app"
              tab="应用"
              style="height: 450px; overflow-y: scroll"
            >
              <div class="header">
                <a-input
                  style="margin: 0 10px"
                  v-model:value="appName"
                  placeholder="请输入名称进行搜索"
                />

                <a-button type="primary" @click="getDataList('app')">
                  查询
                </a-button>
              </div>
              <div
                v-for="item in app"
                :key="item.id"
                @click="setName(item)"
                class="listUser"
              >
                <div class="leftList">
                  <div class="line" v-show="item.active"></div>
                  {{ item.name }}
                </div>
                <div><right-outlined /></div>
              </div>
            </a-tab-pane>
            <!-- <a-tab-pane key="custom" tab="组织机构"> 按客体授权主体</a-tab-pane> -->
          </a-tabs>
        </a-card>
      </a-col>
      <a-col :span="18">
        <a-card class="cardWidth">
          <a-tabs
            size="small"
            v-model:activeKey="rightActiveKey"
            @change="changeTab"
          >
            <a-tab-pane key="user" tab="授权到账户">
              <div class="header">
                <a-input
                  style="width: 180px; margin: 0 15px"
                  v-model:value="userName"
                  placeholder="请输入名称进行搜索"
                />

                <a-button type="primary" @click="getDataList('user', 1)">
                  查询
                </a-button>
              </div>
              <a-table
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                  type: `checkbox`,
                }"
                :loading="loading"
                :columns="columns"
                rowKey="id"
                :scroll="{ y: 500 }"
                :data-source="user"
                :pagination="false"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'status'">
                    <el-popconfirm
                      confirm-button-text="确认"
                      cancel-button-text="取消"
                      title="确认修改应用状态吗"
                      @confirm="confirmSwitch(record)"
                      @cancel="cancelSwitch(record)"
                    >
                      <template #reference>
                        <el-switch
                          @click="clickSwitch(record)"
                          v-model="record.status"
                        />
                      </template>
                    </el-popconfirm>
                  </template>
                </template>
              </a-table>
              <a-pagination
                style="float: right; margin-top: 15px"
                v-model:current="userCurrent"
                v-model:pageSize="userPageSize"
                show-size-changer
                :show-total="(total) => `Total ${total} items`"
                :total="userTotal"
                @showSizeChange="onShowSizeChange"
                @change="onShowSizeChange"
              />
            </a-tab-pane>
            <!-- <a-tab-pane key="department" tab="授权到组织">
              <a-tree
                v-model:expandedKeys="expandedKeys"
                v-model:selectedKeys="selectedKeys"
                v-model:checkedKeys="checkedKeys"
                @select="changeSelect"
                checkable
                :tree-data="department"
              >
                <template #title="{ name }">
                  <span style="color: #1890ff">{{ name }}</span>
                </template>
              </a-tree>
            </a-tab-pane> -->
          </a-tabs>
          <template #actions>
            <a-button type="primary" @click="save">保存</a-button>
          </template>
        </a-card>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import axios from "@/common/axios";
import api from "@/common/api/api";
import { useStore } from "vuex";
// import { message } from "ant-design-vue";
import { localGet, localSet } from "@/common/utils";
// import IdaasDescription from "@/components/IdaasDescription.vue";
import { message } from "ant-design-vue";
export default {
  name: "Application",
  //   components: {
  //     IdaasDescription,
  //   },
  setup() {
    const multipleTable = ref(null);
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      loading: false,
      merchantAppId: "",
      columns: [
        {
          title: "账户名称",
          dataIndex: "userName",
          key: "userName",
        },
        {
          title: "真实姓名",
          dataIndex: "realName",
          key: "realName",
          width: "100",
        },

        {
          title: "角色状态",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "手机号",
          dataIndex: "mobile",
          key: "mobile",
        },
      ],
      leftActiveKey: "app",
      rightActiveKey: "user",
    });
    onMounted(() => {
      state.leftActiveKey = "app";
      state.rightActiveKey = "user";
      getDataList(state.leftActiveKey);
      getDataList(state.rightActiveKey);
    });
    const params = reactive({
      appName: "",
      userName: "",
      userPageSize: 10,
      userCurrent: 1,
      userTotal: 10,
    });
    // 获取列表
    const paramsUrlMap = reactive({
      data: new Map([
        [
          "user",
          {
            url: "getUserList",
            params: {
              mobile: "",
              name: "",
              total: 0,
              page_no: 1,
              page_size: 10,
            },
          },
        ], //账户
        [
          "auth",
          {
            url: "resourceList",
            params: {
              name: "",
              status: "",
              all: true, //是否需要全部权限
              resourceTypes: ["MENU", "FUNC"], //资源类型 MENU菜单 FUNC功能
              tree: true, //是否需要树形结构
              userId: "",
            },
          },
        ], //资源权限
        [
          "app",
          {
            url: "getMerchantAppList",
            params: {
              name: "",
              appResource: "USER",
              total: 0, // 总条数
              page_no: 1, // 当前页
              page_size: 1000, // 分页大小
              old_page_size: 10, //存储请求时的pagesize
            },
          },
        ], //角色
        [
          "department",
          {
            url: "departmentList",
            params: {
              level: "",
              name: "", // 名称
              parentId: "", // 父级ID
              status: true, // 状态1 可用 0禁用
              tree: true, //存储请求时的pagesize
            },
          },
        ], //角色
      ]),
      user: [],
      auth: [{ name: "", key: "", disabled: true, children: [] }],
      role: [],
      selectedRowKeys: [],
      app: [],
      department: [],
      appInfo: "",
    });
    const getDataList = (value, pageNo) => {
      treeData.treeList = [];
      treeData.checkedKeys = [];
      state.loading = true;
      let data = paramsUrlMap.data.get(value);
      pageNo && (params[`${value}Current`] = pageNo);
      data.params.merchantId = store.getters["user/merchantId"];
      data.params.authSysCode = route.query.code;
      data.params.name = params[`${value}Name`];
      data.params.userName = params[`${value}Name`];
      data.params.page_no = params[`${value}Current`];
      data.params.page_size = params[`${value}PageSize`];
      paramsUrlMap.auth[0].name = route.query.name;
      paramsUrlMap.auth[0].key = route.query.code;
      axios
        .post(api[data.url], data.params)
        .then((res) => {
          if (res.list && res.list.length > 0) {
            res.list.forEach((item) => {
              item.status = item.status === 1 ? true : false;
            });
          } else if (res && res.length > 0) {
            res.forEach((item) => {
              item.status = item.status === 1 ? true : false;
            });
          }
          if (value == "user") {
            paramsUrlMap.user = res.list;
            params.userTotal = res.total;
            paramsUrlMap.user.forEach((item) => {
              item.id = String(item.id);
            });
            setTimeout(() => {
              getHasAuthUser();
            }, 100);
          } else if (value == "auth") {
            addKey(res, "childrenList");
            paramsUrlMap.auth[0].children = res;
            treeData.expandedKeys.push(paramsUrlMap.auth[0].key);
          } else if (value == "role") {
            paramsUrlMap.role = res;
          } else if (value == "app") {
            paramsUrlMap.app = res.list;
            setName(paramsUrlMap.app[0]);
          } else if (value == "department") {
            addKey(res, "children");

            paramsUrlMap.department = res;
          }
          state.loading = false;
        })
        .catch((err) => {
          console.log(err);
          state.loading = false;
        });
    };
    const getHasAuthUser = () => {
      let data = {
        merchantId: store.getters["user/merchantId"],
        merchantAppId: paramsUrlMap.appInfo.id,
        userIdList: [],
      };
      data.userIdList = [];
      paramsUrlMap.user.forEach((item) => {
        data.userIdList.push(item.id);
      });
      axios.post(api.appUserList, data).then((res) => {
        //console.log(res);
        let arr = [];
        res.forEach((item) => {
          arr.push(String(item.userId));
        });
        //console.log(arr);
        paramsUrlMap.selectedRowKeys = arr;
      });
    };
    const onSelectChange = (value) => {
      //console.log(paramsUrlMap.selectedRowKeys);
      //console.log(value);
      paramsUrlMap.selectedRowKeys = value;
    };
    const addKey = (value, type) => {
      value.forEach((item) => {
        item.key = item.id + "-" + item.status;
        treeData.expandedKeys.push(item.key);
        if (item[type].length > 0) {
          item.children = item[type];
          addKey(item[type], type);
        }
      });
    };
    // 详情模板 控制页面显示隐藏
    const diagleDate = reactive({
      dialogVisible: false,
      hostAlert: localGet("hostAlert") || "open",
    });
    //关闭 提示信息弹框
    const closeAlert = () => {
      diagleDate.hostAlert = "close";
      localSet("hostAlert", "close");
    };
    const treeData = reactive({
      treeList: [],
      expandedKeys: [],
      selectedKeys: [],
      checkedKeys: [],
      nowKeys: {
        id: "",
        name: "",
      },
    });
    const changeSelect = (value) => {
      let selectedKey = value[0].split("-");

      getDataList(selectedKey[0]);
    };

    const setName = (value) => {
      paramsUrlMap.appInfo = value;
      paramsUrlMap.app.forEach((item) => {
        if (item.id == value.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
      getDataList(state.rightActiveKey);
    };
    const save = () => {
      let data = {
        merchantId: store.getters["user/merchantId"],
        authAppUserList: [],
        status: true,
      };
      // treeData.checkedKeys.forEach((item) => {
      //   //console.log(item.split("-"));
      //   data.userResourceList.push({
      //     resourceId: item.split("-")[0],
      //     status: item.split("-")[1] == "true" ? true : false,
      //   });
      // });
      paramsUrlMap.user.forEach((user) => {
        data.authAppUserList.push({
          userId: user.id,
          status: false,
          appUserCode: user.userName,
          merchantAppId: paramsUrlMap.appInfo.id,
          appCode: paramsUrlMap.appInfo.appCode,
        });
      });
      paramsUrlMap.selectedRowKeys.forEach((item) => {
        data.authAppUserList.forEach((user) => {
          if (user.userId == item) {
            user.status = true;
          }
        });
      });
      axios.post(api.appUserAuth, data).then(() => {
        //console.log(res);
        message.success("保存成功");
      });
    };
    const changeTab = (value) => {
      getDataList(value);
    };
    const onShowSizeChange = () => {
      //console.log(current, size);
      //console.log(params.userCurrent);
      //console.log(params.userPageSize);
      getDataList("user");
    };
    const confirmSwitch = (value) => {
      paramsUrlMap.user.forEach((item) => {
        if (item.id == value.id) {
          item.status = !value.status;
        }
      });
      //console.log(value);
      let data = {
        user: { ...value },
      };
      //console.log(data);
      axios
        .post(api.saveUser, data)
        .then(() => {
          message.success("修改成功");
        })
        .catch(() => {
          paramsUrlMap.user.forEach((item) => {
            if (item.name == value.name) {
              item.status = !value.status;
            }
          });
        });
    };
    const cancelSwitch = () => {};
    const clickSwitch = (value) => {
      //console.log(value);
      paramsUrlMap.user.forEach((item) => {
        if (item.id == value.id) {
          item.status = !value.status;
        }
      });
      //console.log(state.tableData);
    };
    return {
      ...toRefs(state),
      ...toRefs(diagleDate),
      ...toRefs(params),
      ...toRefs(treeData),
      ...toRefs(paramsUrlMap),
      multipleTable,
      changeSelect,
      getDataList,
      closeAlert,
      addKey,
      setName,
      save,
      changeTab,
      onSelectChange,
      getHasAuthUser,
      onShowSizeChange,
      confirmSwitch,
      cancelSwitch,
      clickSwitch,
    };
  },
};
</script>
<style lang="scss" scoped>
.listUser {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 44px;
  padding-right: 10px;
  border: 1px solid ghostwhite;
  .leftList {
    display: flex;
    padding-left: 10px;
  }
  .line {
    font-weight: 900;
    background-color: #1674aa;
    margin-right: 10px;
    margin-left: -10px;
    width: 3px;
    height: 44px;
  }
}
.listUser:hover {
  cursor: pointer;
}
.header {
  display: flex;
  margin-bottom: 10px;
}
</style>
